import React, { useState, useContext, useEffect, Fragment } from 'react';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { CompareToolBar } from '../../molecules/compare-toolbar';
import { authContext } from '../../../context/AuthContext';
import { recommendationService } from '../../../services/recommendationService';
import { modelService } from '../../../services/modelService';
import { titleService } from '../../../services/titleService';
import { CompareRecsList } from '../../molecules/compare-recs-list';
import { CompareBisacList } from '../../molecules/compare-bisac-list';
import { PanelCompareStatistics } from '../../organisms/panel-compare-statistics';
import { compareRecsService } from '../../../services/compareRecsService';
import { compareBisacsService } from '../../../services/compareBisacsService';
import { useModels } from '../../../hooks/useModels';
import { Spinner } from '../../atoms/spinner';
import {
  RECS_TAB,
  METRICS_TAB,
  PARAMS_TAB,
  BISAC_TAB,
  ML_TARGET,
  POPULARITY,
  FLRID_FILTER,
  TOP_N_OPTIONS,
  GRADE_OPTIONS,
  LANGUAGE_OPTIONS,
  PUB_YEAR_OPTIONS,
  REVIEW_COUNT_OPTIONS,
  SOURCE_OPTIONS
} from '../../../constants/constants';

import './styles.dashboard.scss';

const EmpyCompare = () => {
  return (
    <div className='alert alert-light user-info'>
      <p>In this view you could compare up to 4 different models</p>

      <p>To compare follow the next intructions:</p>
      <ol>
        <li>
          Select the model type
        </li>
        <li>
          Select an specific model and add it with the plus button
        </li>
        <li>
          When you have more than 2 models added, the compare button will be clickable
        </li>
      </ol>

    <p>With the  clear button you can clean the information and make a new comparision</p>
    </div>
  );
};

export const CompareModels = () => {
  const {
    models,
    modelTypeOptions,
    modelsBasedOnType,
    updateModelTypeOptions,
    updteModelsBasedOnType,
    getModelInfoToCompare
  } = useModels();

  const { siteGuid, appId } = useContext(authContext);
  const [paramsKeys, setParamsKeys] = useState([]);
  const [metricsKeys, setMetricsKeys] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [removedModels, setRemovedModels] = useState([]);
  const [modelType, setModelType] = useState();
  const [topN, setTopN] = useState(50);
  const [grades, setGrades] = useState(['all']);
  const [languages, setLanguages] = useState(['all']);
  const [fromPubYear, setFromPubYear] = useState(0);
  const [toPubYear, setToPubYear] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [source, setSource] = useState('all');

  const [titlesData, setTitlesData] = useState([]);

  const [disableCompare, setDisableCompare] = useState(true);
  const [disableAdd, setDisableAdd] = useState(false);

  const [compareMatrix, setCompareMatrix] = useState([]);
  const [compareTabSelected, setCompareTabSelected] = useState(RECS_TAB);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(
    function () {
      updateModelTypeOptions(ML_TARGET, [POPULARITY]);
    },
    [models]
  );

  useEffect(
    function () {
      const defaultType = modelType ? modelType.value : undefined;
      updteModelsBasedOnType(ML_TARGET, defaultType);
    },
    [modelType]
  );

  useEffect(
    function () {
      const options = modelsBasedOnType.filter(
        (item) => !removedModels.includes(item.guid)
      );
      setModelOptions(options);
    },
    [modelsBasedOnType]
  );

  useEffect(
    function () {
      if (compareMatrix.length > 1) {
        onClickCompareModels();
      }
    },
    [siteGuid, topN, grades, languages, fromPubYear, toPubYear, reviewCount, source]
  );

  const updateTopN = (selectedOption) => {
    setTopN(selectedOption.value);
  };

  const updateGrades = (selectedOption) => {
    setGrades([selectedOption.value]);
  };

  const updateLanguages = (selectedOption) => {
    setLanguages([selectedOption.value]);
  };

  const updateFromPubYear = (selectedOption) => {
    setFromPubYear(selectedOption.value);
  };

  const updateToPubYear = (selectedOption) => {
    setToPubYear(selectedOption.value);
  };

  const updateReviewCount = (selectedOption) => {
    setReviewCount(selectedOption.value);
  };

  const updateSource = (selectedOption) => {
    setSource(selectedOption.value);
  };

  const updateTypeModel = (option) => {
    setModelType(option);
  };

  const onClickAddModel = (element) => {
    if (errorMessage) {
      setErrorMessage();
    }

    if (!element || element.length === 0) {
      alert('you must select a model');
      return;
    }

    if (compareMatrix.length >= 1) {
      setDisableCompare(false);
    }

    if (compareMatrix.length > 3) {
      alert('you can only add 4 models');
      return;
    }

    setRemovedModels([...removedModels, element.value]);
    const options = modelOptions.filter((model) => model.guid !== element.value);
    setModelOptions(options);

    const compareModel = getModelInfoToCompare(element.value);

    setCompareMatrix([...compareMatrix, compareModel]);
  };

  const onClickCompareModels = async () => {
    setLoading(true);
    setDisableCompare(true);
    setDisableAdd(true);

    const temp = {
      metricsKeys: [],
      paramsKeys: [],
      flridArray: []
    };

    const matrix = getBaseMatrix(compareMatrix, temp, siteGuid, appId, topN, grades, languages, fromPubYear, toPubYear, reviewCount, source);

    setParamsKeys(Array.from(new Set(temp.paramsKeys)));
    setMetricsKeys(Array.from(new Set(temp.metricsKeys)));

    Promise.all(matrix)
      .then(async (values) => {
        setErrorMessage();

        const titlesData = await titleService.getTitlesByFilter(
          temp.flridArray,
          FLRID_FILTER,
          siteGuid
        );
        setTitlesData(titlesData);

        compareBisacsService.compareBisacs(values, titlesData);

        const recommendationsResults =
          compareRecsService.compareRecommendations(values);
        setCompareMatrix(recommendationsResults);
      })
      .catch((error) => {
        console.error(error);
        onClickResetComparison();
        setErrorMessage('Could not fetch the information, Please try another model or site');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickResetComparison = () => {
    setModelOptions(modelsBasedOnType);
    setRemovedModels([]);
    setCompareMatrix([]);
    setParamsKeys([]);
    setMetricsKeys([]);
    setDisableAdd(false);
  };

  return (
    <Fragment>
      <CompareToolBar
        modelOptions={modelService.getModelOptions(modelOptions)}
        modelTypeOptions={modelTypeOptions}
        selectedType={modelType}
        onChangeModelType={updateTypeModel}
        disableCompare={disableCompare}
        disableAdd={disableAdd}
        handleAddModel={onClickAddModel}
        handleCompare={onClickCompareModels}
        handleReset={onClickResetComparison}
        topNOptions={TOP_N_OPTIONS}
        selectedTopN={topN}
        onChangeTopN={updateTopN}
        gradeOptions={GRADE_OPTIONS}
        selectedGrades={grades}
        onChangeGrades={updateGrades}
        languageOptions={LANGUAGE_OPTIONS}
        selectedLanguages={languages}
        onChangeLanguages={updateLanguages}
        pubYearOptions={PUB_YEAR_OPTIONS}
        selectedFromPubYear={fromPubYear}
        onChangeFromPubYear={updateFromPubYear}
        selectedToPubYear={toPubYear}
        onChangeToPubYear={updateToPubYear}
        reviewCountOptions={REVIEW_COUNT_OPTIONS}
        selectedReviewCount={reviewCount}
        onChangeReviewCount={updateReviewCount}
        sourceOptions={SOURCE_OPTIONS}
        selectedSource={source}
        onChangeSource={updateSource}
      />
      <div>
        <Tabs
          id="controlled-tab-example"
          activeKey={compareTabSelected}
          onSelect={(k) => setCompareTabSelected(k)}
          className="mb-3"
        >
          <Tab eventKey={RECS_TAB} title="Recommendations">
            {loading && <Spinner></Spinner>}
            { !errorMessage && compareMatrix.length < 1 && <EmpyCompare /> }
            { errorMessage &&
              (<div className='error-container'>
                <p className='error-msg'>{errorMessage}</p>
                </div>
              )}
            <CompareRecsList
              compareMatrix={compareMatrix}
              titlesData={titlesData}
            />
          </Tab>
          <Tab eventKey={PARAMS_TAB} title="Params" disabled={!disableAdd}>
            <PanelCompareStatistics
              keySet={paramsKeys}
              compareMatrix={compareMatrix}
              statisticsAttribute={PARAMS_TAB}
            />
          </Tab>
          <Tab eventKey={METRICS_TAB} title="Metrics" disabled={!disableAdd}>
            <PanelCompareStatistics
              keySet={metricsKeys}
              compareMatrix={compareMatrix}
              statisticsAttribute={METRICS_TAB}
            />
          </Tab>
          <Tab eventKey={BISAC_TAB} title="BISAC" disabled={!disableAdd}>
            <CompareBisacList compareMatrix={compareMatrix} />
          </Tab>
        </Tabs>
      </div>
    </Fragment>
  );
};

function getBaseMatrix (compareMatrix, temp, siteGuid, appId, topN, grades, languages, fromPubYear, toPubYear, reviewCount, source) {
  return compareMatrix.map(async (model) => {
    modelService.getStatisticsKeysFromModel(model, temp);
    const newModel = await addRecommendationsToModel(model, siteGuid, appId, topN, grades, languages, fromPubYear, toPubYear, reviewCount, source);
    fillFlridArray(temp, newModel.recs);

    return newModel;
  });
}

async function addRecommendationsToModel (model, siteGuid, appId, topN, grades, languages, fromPubYear, toPubYear, reviewCount, source) {
  const response = await recommendationService.getRecommendations(
    model,
    siteGuid,
    appId,
    topN,
    grades,
    languages,
    fromPubYear,
    toPubYear,
    reviewCount,
    source
  );

  return {
    ...model,
    recs: response.recs
  };
}

function fillFlridArray (obj, recs) {
  const temp = titleService.getFlridArray(recs);
  obj.flridArray = [...obj.flridArray, ...temp];
}
