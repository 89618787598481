import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { GRID_VIEW } from '../../../constants/constants';
import { BOOK_IMAGE_URL } from '../../../constants/enviroment';
import noTitleThumbnail from '../../../../../public/images/titleNotFound.png';

import './styles.rec-item.scss';

export const RecommendationItem = (props) => {
  const { viewType } = props;

  return (
    viewType === GRID_VIEW ? <GridView {...props} /> : <ListView {...props} />
  );
};

const GridView = (props) => {
  const { item, titleData, viewType, handleShow } = props;

  const source = item.rec_source ? item.rec_source.join(', ') : '';
  const genre = item.genre ? item.genre : '';

  return (
    <Card className={`rec-item ${viewType}`}>
      <Card.Img
        src={`${BOOK_IMAGE_URL}${item.isbn_13}`}
        onClick={() => handleShow(titleData)}
        onLoad={(a) => {
          if (a.target.naturalWidth === 1) {
            a.target.src = noTitleThumbnail;
          }
        }}
        onError={(a) => {
          a.target.src = noTitleThumbnail;
        }}
      />
      <Card.Subtitle className="mt-2 mb-2 text-muted ">
        {item.isbn_13}
      </Card.Subtitle>
      <div className="fil-source">
        {item.fil && item.fil.length > 0
          ? (
              item.fil.map((fil) => {
                return (
              <span key={fil} className="fil">{fil}</span>
                );
              })
            )
          : (
          <span className="fil">-</span>
            )}
        {source && (
          <div className="rec-source">{source} pool</div>
        )}
        {genre && (
          <div className="genre">{genre}</div>
        )}
      </div>
      <div className="title">
        {titleData?.title || '-'}
      </div>
    </Card>
  );
};

const ListView = (props) => {
  const { index, item, titleData, handleShow } = props;

  const source = item.rec_source ? item.rec_source.join(', ') : '';
  const genre = item.genre ? item.genre : '';

  return (
    <div>
      <div>
        <span className="now">{index + 1}</span>
      </div>
      <div>
        <img
          src={`${BOOK_IMAGE_URL}${item.isbn_13}`}
          onClick={() => handleShow(titleData)}
          onLoad={(a) => {
            if (a.target.naturalWidth === 1) {
              a.target.src = noTitleThumbnail;
            }
          }}
          onError={(a) => {
            a.target.src = noTitleThumbnail;
          }}
        />
        <span className={`score-${item.scoreTag}`}>{item.score !== undefined ? item.score.toFixed(4) : '-'}</span>
      </div>

      <div>
        <div>
          <span>{titleData?.title}</span>
          <span className={`s${titleData?.status}`}>{mapStatus(titleData?.status) || '-'}</span>
        </div>

        <div>
          <span>{item.isbn_13}</span>
        </div>

        <div>
          <span>{titleData?.author || '-'}</span>,
          <span>{titleData?.illustrator || '-'}</span>
        </div>

        <div>
          {titleData?.publication_year || '-'},
          {titleData?.language || '-'},
          {titleData?.format || '-'},
          {titleData?.publisher_name || '-'}
        </div>

        <div>
          {item.fil && item.fil.length > 0
            ? (
                item.fil.map((fil) => {
                  return (
                <span key={fil} className="fil">{fil}</span>
                  );
                })
              )
            : (
            <span className="fil">-</span>
              )}
          {source && (
            <span className="rec-source">{source} pool</span>
          )}
          {genre && (
            <span className="genre">{genre}</span>
          )}
          <span>{titleData?.is_fiction ? 'fiction' : 'non-fiction' || ''}</span>
        </div>

        <div>
          {titleData?.subjects &&
            titleData.subjects.map((subject, index) => {
              return (
                <span key={subject}>{subject}</span>
              );
            })
          }
        </div>

        <div>
          {titleData?.bisacs &&
            titleData.bisacs.map((bisac, index) => {
              let desc = bisac.description.replace(
                /^JUVENILE NONFICTION \//i,
                'JNF /'
              );
              desc = desc.replace(/^JUVENILE FICTION \//i, 'JF /');
              desc = desc.replace(/^YOUNG ADULT FICTION \//i, 'YAF /');
              const headingIndex = desc.lastIndexOf('/', desc.lastIndexOf('/') - 1) + 1;
              return (
                <span key={bisac.description}>{desc.substring(headingIndex)}</span>
              );
            })
          }
        </div>

      </div>
    </div>
  );
};

function mapStatus (status) {
  switch (status) {
    case 0:
      return 'Deleted';
    case 1:
      return 'Pre-Sales';
    case 2:
      return 'Non-sellable';
    case 3:
      return 'Sellable';
    default:
      return 'Unknown';
  }
}

GridView.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  titleData: PropTypes.object,
  viewType: PropTypes.string,
  handleShow: PropTypes.func
};

ListView.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  titleData: PropTypes.object,
  viewType: PropTypes.string,
  handleShow: PropTypes.func
};

RecommendationItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  titleData: PropTypes.object,
  viewType: PropTypes.string,
  handleShow: PropTypes.func
};
